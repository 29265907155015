import log from 'modules/player/log';
import VideoServiceCallbacks from 'modules/player/video/services/helpers/video_service_callbacks';
import defer from 'plugins/utilities/defer';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import now from 'plugins/utilities/now';
import WistiaPlayerApiLoader from 'wistia-player-api-loader';

const LOG_TAG = 'Wistia';

const valueOrDefault = (object, key, defaultValue) =>
  isVariableDefinedNotNull(object[key]) ? object[key] : defaultValue;

export default class WistiaV2 {
  constructor(element, options) {
    this._element = element;
    this._options = options;

    this._videoId = null;

    this._ratio = valueOrDefault(this._options.initial, 'ratio', 16 / 9.0);

    this._timing = {
      createdAt: now(),
      initializingStartedAt: undefined,
      initializedAt: undefined,
      loadedAt: undefined,
      firstReadyAt: undefined,
    };

    this._volume = {
      volume: valueOrDefault(this._options.initial, 'volume', 100),
      muted: valueOrDefault(this._options.initial, 'muted', false),
    };

    this._playbackRate = {
      availablePlaybackRates: [
        {
          key: '1',
          name: `1 &times;`,
          playbackRate: 1,
        },
      ],
      activePlaybackRateIndex: '1',
    };

    this._quality = {
      quality: valueOrDefault(this._options.initial, 'quality', null),

      availableQualities: [],
      activeQualityIndex: -1,
      currentQualityName: 'Auto',
    };

    this._subtitles = {
      addedTrackIds: [],
      trackElements: [],
      availableSubtitles: [
        {
          key: 'Off/off',
          name: 'Off',
          language: 'off',
        },
      ],
      activeSubtitlesIndex: 'Off/off',
    };

    this._state = {
      initializing: false,
      initialized: false,
      loading: false,
      ready: false,
      playbackState: '',

      playOnCanPlay: false,
      firstPlay: true,
      seekable: true,
      currentTime: 0,
      duration: 0,
    };

    this._callbacks = new VideoServiceCallbacks();

    this._wistia = null;
  }

  on(event, callback) {
    this._callbacks.on(event, callback);
  }

  _runCallbacks(event, ...args) {
    this._callbacks.run(event, ...args);
  }

  load(sources, { videoId }) {
    if (sources) {
      console.warn(LOG_TAG, 'multiple sources are not supported');
    }

    if (!videoId) {
      console.warn(LOG_TAG, 'video id must be set');
    }

    this.loading = true;
    this._state.initializing = true;
    this._timing.initializingStartedAt = now();
    this._videoId = videoId;

    WistiaPlayerApiLoader.load((WistiaSDK) => this._load(WistiaSDK));
  }

  // public methods

  play() {
    log(LOG_TAG, 'play request');

    this._state.ended = false;
    this._wistia.play();
  }

  pause() {
    log(LOG_TAG, 'pause request');

    this._wistia.pause();
  }

  seekToLivePosition() {
    console.warn(LOG_TAG, 'Not implemented:', 'seekToLivePosition');
  }

  loadSubtitles(subtitles) {
    console.warn(LOG_TAG, 'Not implemented:', 'loadSubtitles', subtitles);
  }

  destroy() {
    console.warn(LOG_TAG, 'Not implemented:', 'destroy');
  }

  // public getters and setters

  get useHlsJs() {
    return false;
  }

  get videoElement() {
    return null;
  }

  get activeVideoSourceIndex() {
    return 0;
  }

  // eslint-disable-next-line no-empty-function
  set activeVideoSourceIndex(index) {}

  get ready() {
    return this._state.ready;
  }

  get loading() {
    return this._state.loading;
  }

  set loading(loading) {
    if (loading === this._state.loading) {
      return;
    }

    this._state.loading = loading;
    this._runCallbacks('loadingChanged', this._state.loading);
  }

  get duration() {
    return this._state.duration;
  }

  get currentTime() {
    return this._state.currentTime;
  }

  set currentTime(value) {
    const currentTimeBeforeSeek = this.currentTime;

    this._wistia.time(value / 1000.0);

    this._runCallbacks('seekRequest', currentTimeBeforeSeek, value);
  }

  get programDateTime() {
    return this.currentTime;
  }

  get state() {
    return this._state.state;
  }

  set state(state) {
    if (state === this._state.state) {
      return;
    }

    this._state.state = state;
    this._runCallbacks('stateChanged', this._state.state);
  }

  get seeking() {
    return this._state.seeking;
  }

  set seeking(seeking) {
    this._state.seeking = seeking;
  }

  get volume() {
    return this._volume.volume;
  }

  set volume(value) {
    this._volume.muted = false;
    this._volume.volume = value;

    if (this._wistia) this._wistia.volume(this._volume.volume / 100.0);

    this._runCallbacks('volumeChanged', this._volume.volume, this._volume.muted);
  }

  get muted() {
    return this._volume.muted;
  }

  set muted(muted) {
    this._volume.muted = muted;

    if (this._wistia) {
      if (muted) {
        this._wistia.volume(0);
      } else {
        this._wistia.volume(this._volume.volume / 100.0);
      }
    }

    this._runCallbacks('volumeChanged', this._volume.volume, this._volume.muted);
  }

  get realPlaybackRate() {
    return this.activePlaybackRate ? this.activePlaybackRate.playbackRate : 1;
  }

  set realPlaybackRate(rate) {
    if (this._wistia) {
      this._wistia.playbackRate(rate);
    }
  }

  get activePlaybackRate() {
    return this._playbackRate.availablePlaybackRates.find(
      (rate) => rate.key === this._playbackRate.activePlaybackRateIndex,
    );
  }

  get activePlaybackRateIndex() {
    return this._playbackRate.activePlaybackRateIndex;
  }

  set activePlaybackRateIndex(index) {
    if (index !== this._playbackRate.activePlaybackRateIndex) {
      this._playbackRate.activePlaybackRateIndex = index;

      this._wistia.playbackRate(this.activePlaybackRate ? this.activePlaybackRate.playbackRate : 1);

      this._runCallbacks('activePlaybackRateIndexChanged', this._playbackRate.activePlaybackRateIndex);
    }
  }

  get activeQuality() {
    return this._quality.availableQualities.find((quality) => quality.key === this._quality.activeQualityIndex);
  }

  get activeQualityIndex() {
    return this._quality.activeQualityIndex;
  }

  set activeQualityIndex(index) {
    if (index !== this._quality.activeQualityIndex) {
      this._quality.activeQualityIndex = index;

      this._runCallbacks('activeQualityIndexChanged', this._quality.activeQualityIndex);
    }
  }

  get activeSubtitles() {
    return this._subtitles.availableSubtitles.find(
      (subtitles) => subtitles.key === this._subtitles.activeSubtitlesIndex,
    );
  }

  get activeSubtitlesIndex() {
    return this._subtitles.activeSubtitlesIndex;
  }

  set activeSubtitlesIndex(index) {
    if (index !== this._subtitles.activeSubtitlesIndex) {
      this._subtitles.activeSubtitlesIndex = index;

      this._runCallbacks('renderSubtitlesWord', null);
      this._runCallbacks('activeSubtitlesIndexChanged', this._subtitles.activeSubtitlesIndex);
    }
  }

  get activeVideoSourceUrl() {
    return '';
  }

  get activeQualityName() {
    return this.activeQuality ? this.activeQuality.name : null;
  }

  get activePlaybackRateValue() {
    return this.activePlaybackRate ? this.activePlaybackRate.playbackRate : null;
  }

  get live() {
    return false;
  }

  get liveFinished() {
    return false;
  }

  // eslint-disable-next-line no-empty-function
  set liveFinished(liveFinished) {}

  // eslint-disable-next-line no-empty-function
  set liveSubtitlesEnabled(enabled) {}

  // eslint-disable-next-line no-empty-function
  set liveSubtitlesDelayMs(delayMs) {}

  get inLivePosition() {
    return false;
  }

  get ratio() {
    return this._ratio;
  }

  // eslint-disable-next-line no-empty-function
  set size(size) {}

  //

  _load(WistiaSDK) {
    this._state.initializing = false;
    this._state.initialized = true;
    this._timing.initializedAt = now();

    this._wistia = WistiaSDK.embed(this._videoId, {
      container: this._element.getAttribute('id'),
      autoPlay: false,
      controlsVisibleOnLoad: false,
      doNotTrack: true,
      endVideoBehavior: 'default',
      fullscreenButton: false,
      googleAnalytics: false,
      muted: false,
      playbackRateControl: false,
      playbar: false,
      playButton: false,
      preload: 'metadata',
      qualityControl: false,
      qualityMax: 3840,
      qualityMin: 224,
      seo: false,
      settingsControl: false,
      silentAutoPlay: false,
      smallPlayButton: false,
      time: 0,
      videoFoam: true,
      volumeControl: false,
      wmode: 'transparent',
    });

    this._wistia.hasData((data) => this._processWistiaData(data));
    this._wistia.bind('play', (data) => this._onWistiaPlay(data));
    this._wistia.bind('pause', (data) => this._onWistiaPause(data));
    this._wistia.bind('ended', (data) => this._onWistiaEnded(data));
    this._wistia.bind('timechange', (data) => this._onWistiaTimeChange(data));

    this._state.loaded = true;
    this._timing.loadedAt = now();
  }

  _processWistiaData(data) {
    log(LOG_TAG, 'data', data);

    this._canPlay();
  }

  onWistiaPlay(data) {
    log('WISTIA', 'play', data);

    this.loading = false;
    this.state = 'playing';
    this._state.ended = false;
  }

  onWistiaPause(data) {
    log(LOG_TAG, 'pause', data);

    this.loading = false;
    this.state = 'paused';
  }

  _onWistiaEnded(data) {
    log(LOG_TAG, 'ended', data);

    this.loading = false;
    this.state = 'paused';

    this._state.ended = true;
    this._runCallbacks('ended');
  }

  _onWistiaTimeChange(data) {
    log(LOG_TAG, 'timechange', data);

    this._state.duration = Math.floor(this._wistia.duration() * 1000);
    this._state.currentTime = Math.floor(data * 1000);
  }

  _canPlay() {
    if (!this.ready) {
      this._setReady();
    }
  }

  _setReady() {
    if (!this._timing.firstReadyAt) this._timing.firstReadyAt = now();

    this._state.duration = Math.floor(this._wistia.duration() * 1000);

    this._updateAvailablePlaybackRates();
    this._updateAvailableQualities();
    this._updateAvailableSubtitles();

    if (this._volume.muted) {
      this._wistia.volume(0);
    } else {
      this._wistia.volume(this._volume.volume);
    }

    this._runCallbacks('volumeChanged', this.volume, this.muted);

    this._state.seekable = true;
    this._runCallbacks('seekableChanged', this._state.seekable);

    this.loading = false;
    this.state = 'paused';

    defer(() => {
      this._state.ready = true;
      this._runCallbacks('ready');
    });
  }

  _updateAvailablePlaybackRates() {
    const previousPlaybackRateKey = this.activePlaybackRate?.key;

    const playbackRates = [0.5, 1, 1.25, 1.5, 2];

    this._playbackRate.availablePlaybackRates = playbackRates.map((playbackRate) => ({
      key: playbackRate.toString(),
      name: `${playbackRate} &times;`,
      playbackRate,
    }));

    this._runCallbacks('availablePlaybackRatesChanged', this._playbackRate.availablePlaybackRates);

    const activePlaybackRateKey =
      this._playbackRate.availablePlaybackRates.findIndex((rate) => rate.key === previousPlaybackRateKey) >= 0
        ? previousPlaybackRateKey
        : '1';

    if (this.activePlaybackRateIndex !== activePlaybackRateKey) {
      this.activePlaybackRateIndex = activePlaybackRateKey;
    } else {
      this._runCallbacks('activePlaybackRateIndexChanged', this._playbackRate.activePlaybackRateIndex);
    }
  }

  _updateAvailableQualities() {
    let activeQualityKey;
    const availableQualities = [
      {
        key: 'auto',
        name: 'Auto',
        level: -1,
        bitrate: Infinity,
      },
    ];

    if (
      !isVariableDefinedNotNull(activeQualityKey) ||
      this._quality.activeQualityIndex === -1 ||
      this._quality.activeQualityIndex === 'auto'
    ) {
      activeQualityKey = 'auto';
    }

    availableQualities.sort((a, b) => a.bitrate - b.bitrate);

    if (activeQualityKey === 'auto') {
      availableQualities.find((quality) => quality.key === 'auto').name = `Auto (${this._quality.currentQualityName})`;
    }

    this._quality.availableQualities = availableQualities;
    this._runCallbacks('availableQualitiesChanged', this._quality.availableQualities);

    if (this.activeQualityIndex !== activeQualityKey) this.activeQualityIndex = activeQualityKey;
  }

  _updateAvailableSubtitles() {
    const previousSubtitlesKey = this.activeSubtitles?.key;
    const availableSubtitles = [
      {
        key: 'Off/off',
        name: 'Off',
        language: 'off',
      },
    ];

    this._subtitles.availableSubtitles = availableSubtitles;
    this._runCallbacks('availableSubtitlesChanged', this._subtitles.availableSubtitles);

    const activeSubtitlesIndex =
      this._subtitles.availableSubtitles.findIndex((subtitles) => subtitles.key === previousSubtitlesKey) >= 0
        ? previousSubtitlesKey
        : 'Off/off';

    if (this.activeSubtitlesIndex !== activeSubtitlesIndex) {
      this.activeSubtitlesIndex = activeSubtitlesIndex;
    } else {
      this._runCallbacks('activeSubtitlesIndexChanged', this._subtitles.activeSubtitlesIndex);
    }
  }
}
