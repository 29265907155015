import Hls from 'hls.js';
import log from 'modules/player/log';
import now from 'plugins/utilities/now';

const LOG_TAG = 'HLSv3';

function handleHlsJsError(hls, error) {
  if (error.fatal || error.type !== Hls.ErrorTypes.NETWORK_ERROR) {
    if (hls.started || error.type !== Hls.ErrorTypes.NETWORK_ERROR) {
      console.warn(LOG_TAG, 'hls.js', 'fatal error', error);
    }
  } else {
    log(LOG_TAG, 'hls.js', 'error', error);
  }

  if (error.type === Hls.ErrorTypes.MEDIA_ERROR) {
    if (error.details === Hls.ErrorDetails.BUFFER_APPEND_ERROR && error.err && error.err.code === 11) {
      // workaround for videos getting stuck in Google Chrome
      console.warn(LOG_TAG, 'hls.js', 'recovering from media buffer append error by reloading video');
      // hls._sourceSwitcher.reloadVideo();

      hls._hlsJsErrors.recoverMediaBufferAppendErrorCounter += 1;

      return;
    }

    if (error.fatal) {
      const lastErrorLongAgo =
        !hls._hlsJsErrors.lastRecoverMediaErrorCallAt || now() - hls._hlsJsErrors.lastRecoverMediaErrorCallAt >= 5000;

      if (lastErrorLongAgo) {
        console.warn(LOG_TAG, 'hls.js', 'recovering from fatal media error');
        hls._hlsJs.recoverMediaError();

        hls._hlsJsErrors.recoverMediaErrorCounter += 1;
        hls._hlsJsErrors.lastRecoverMediaErrorCallAt = now();

        return;
      }

      if (!hls._hlsJsErrors.hlsJsAudioCodecsSwapped) {
        console.warn(LOG_TAG, 'hls.js', 'recovering from fatal media error using audio codec swap');

        hls._hlsJs.swapAudioCodec();
        hls._hlsJs.recoverMediaError();

        hls._hlsJsErrors.recoverMediaErrorCounter += 1;
        hls._hlsJsErrors.audioCodecsSwapped = true;
        hls._hlsJsErrors.lastRecoverMediaErrorCallAt = now();

        return;
      }
    }
  } else if (error.type === Hls.ErrorTypes.NETWORK_ERROR) {
    hls._updateLiveStreamStarted(false);

    if (error.fatal) {
      if (
        error.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR ||
        error.details === Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT ||
        error.details === Hls.ErrorDetails.MANIFEST_PARSING_ERROR
      ) {
        hls._sourceSwitcher.reload();

        return;
      }

      log(LOG_TAG, 'hls.js', 'recovering from fatal network error');
      hls._hlsJs.startLoad();

      return;
    }
  }

  if (error.fatal) {
    hls.reportError(error, {
      source: 'hls_js_listener',
      nativeError: false,
    });
  }
}

function handleNativeError(hls, error, errorEvent) {
  log(LOG_TAG, 'native', 'error', error, errorEvent);

  if (!error) return;

  // MediaError - https://developer.mozilla.org/en-US/docs/Web/API/MediaError
  // error.code === 1: MEDIA_ERR_ABORTED
  // error.code === 2: MEDIA_ERR_NETWORK
  // error.code === 3: MEDIA_ERR_DECODE
  // error.code === 4: MEDIA_ERR_SRC_NOT_SUPPORTED

  // networkState - https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/networkState
  // video.networkState === 0: NETWORK_EMPTY
  // video.networkState === 1: NETWORK_IDLE
  // video.networkState === 2: NETWORK_LOADING
  // video.networkState === 3: NETWORK_NO_SOURCE

  // workaround for videos getting stuck in Google Chrome
  if (hls._hlsJs) {
    if (error.code === 3) {
      console.warn(LOG_TAG, 'native', 'recovering from MEDIA_ERR_DECODE by reloading video');

      setTimeout(() => {
        // hls.loading = true;

        hls.currentTime += 2000;
        // hls.sourceSwitcher.reloadVideo();
      }, 0);

      hls._hlsJsErrors.recoverMediaErrDecodeCounter += 1;
    }

    return;
  }

  if (hls.videoElement.networkState === 3) {
    hls._updateLiveStreamStarted(false);
    hls._sourceSwitcher.reload();

    return;
  }

  console.warn(LOG_TAG, 'native', 'error', error);

  hls.reportError(error, {
    source: 'native_listener',
    nativeError: true,
  });
}

export { handleHlsJsError, handleNativeError };
