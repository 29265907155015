import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

import LoadingDurationLogger from '../loading_duration_logger';
import HlsV3 from './services/hls_v3';
import VimeoV2 from './services/vimeo_v2';
import WistiaV2 from './services/wistia_v2';
import YoutubeV2 from './services/youtube_v2';

const valueOrDefault = (object, key, defaultValue) =>
  isVariableDefinedNotNull(object[key]) ? object[key] : defaultValue;

function yodaVideoSources(videoId, videoSourcesData) {
  return ((videoSourcesData && videoSourcesData.videoCdnServers) || [])
    .flatMap((host) => [
      { key: `${host}/hls`, name: host, format: 'hls', url: `https://${host}/${videoId}/master.m3u8` },
      { key: `${host}/dash`, name: host, format: 'dash', url: `https://${host}/${videoId}/master.mpd` },
    ])
    .filter((source) => source.format === 'hls');
}

function urlVideoSources(videoId) {
  const urls = [videoId];
  return urls.map((url) => ({ key: url, name: url, format: 'mp4', url }));
}

function wowzaVideoSources(servers, streamKeyParam) {
  return servers.map((server) => {
    const url =
      streamKeyParam === 'slides_stream_key'
        ? server.server_info.abr_slides_playback_url
        : server.server_info.abr_playback_url;

    return { key: url, name: server.server_info.human_name, format: 'hls', url };
  });
}

export default class VideoPlayer {
  constructor(element, options) {
    this._element = element;
    this._options = options;
    this._videoServiceName = '';

    this._loadingDurationLogger = new LoadingDurationLogger('VIDEO', {
      event: (event) => this._runCallbacks('loadingEvent', 'loadingEvent', event),
    });

    this._callbacks = {
      ratioChanged: new Set(),
      ready: new Set(),
      loadingChanged: new Set(),
      loadingEvent: new Set(),
      stateChanged: new Set(),
      timesChanged: new Set(),
      seekableChanged: new Set(),
      seekRequest: new Set(),
      seeking: new Set(),
      seeked: new Set(),
      volumeChanged: new Set(),
      playFailed: new Set(),
      ended: new Set(),
      programDateTimeChanged: new Set(),
      timeUpdate: new Set(),

      playbackRateChanged: new Set(), // deprecated
      activePlaybackRateIndexChanged: new Set(),
      availablePlaybackRatesChanged: new Set(),

      qualityChanged: new Set(), // deprecated
      activeQualityIndexChanged: new Set(),
      availableQualitiesChanged: new Set(),

      activeSubtitlesChanged: new Set(), // deprecated
      activeSubtitlesIndexChanged: new Set(),
      subtitlesChanged: new Set(), // deprecated
      availableSubtitlesChanged: new Set(),
      renderSubtitlesWord: new Set(),

      playbackServerChanged: new Set(), // deprecated
      activeVideoSourceIndexChanged: new Set(),
      availableServersChanged: new Set(), // deprecated
      availableVideoSourcesChanged: new Set(),

      livestreamStartChanged: new Set(), // deprecated
      liveStreamStartedChanged: new Set(),
      inLivePositionChanged: new Set(),
      streamStartChanged: new Set(),
      timeSinceStreamStartChanged: new Set(),

      showError: new Set(),
      reportError: new Set(),
      debugInfo: new Set(),

      firstPlayHackChanged: new Set(),
    };

    const initialPropValues = options.initial || {};
    this._initial = {
      ratio: valueOrDefault(initialPropValues, 'ratio', 16 / 9.0),
      volume: valueOrDefault(initialPropValues, 'volume', 100),
      muted: valueOrDefault(initialPropValues, 'muted', false),
      playbackRate: valueOrDefault(initialPropValues, 'playbackRate', 1),
      quality: valueOrDefault(initialPropValues, 'quality', 'auto'),
      subtitlesEnabled: valueOrDefault(initialPropValues, 'subtitlesEnabled', true),
      subtitlesLanguage: valueOrDefault(initialPropValues, 'subtitlesLanguage', 'en'),
      liveSubtitlesDelayMs: valueOrDefault(initialPropValues, 'liveSubtitlesDelayMs', 0),
      liveFinished: valueOrDefault(initialPropValues, 'liveFinished', false),
    };

    this._setVideoElementId();
  }

  on(event, callback) {
    if (!this._callbacks[event]) {
      console.warn(`Unknown listener in VideoPlayer.on: ${event}`);
      return;
    }

    this._callbacks[event].add(callback);
  }

  _runCallbacks(event, deprecatedEvent, ...args) {
    if (deprecatedEvent && this._options.callbacks && this._options.callbacks[deprecatedEvent]) {
      this._options.callbacks[deprecatedEvent](...args);
    }

    if (this._callbacks[event]) {
      for (const callback of this._callbacks[event]) {
        callback(...args);
      }
    }
  }

  load(service, videoId, videoServiceData) {
    switch (service) {
      case 'yoda':
      case 'url':
        this._loadHlsV3Service(service, { videoId, data: videoServiceData });
        break;
      case 'vimeo':
        this._loadVimeoV2Service({ videoId });
        break;
      case 'youtube':
        this._loadYouTubeV2Service({ videoId });
        break;
      case 'wistia':
        this._loadWistiaV2Service({ videoId });
        break;
      default:
        this._runCallbacks('showError', 'showError', 'Unsupported video source.');
    }
  }

  loadLive(servers, streamKeyParam) {
    let serverType = null;

    if (servers.length === 0) {
      serverType = 'wowza';
    } else {
      for (const server of servers) {
        if (!isVariableDefinedNotNull(serverType)) {
          serverType = server.server_type;
        } else if (serverType !== server.server_type) {
          this._runCallbacks('showError', 'showError', 'Mixed live server types.');
          return;
        }
      }
    }

    switch (serverType) {
      case 'youtube':
        this._loadYouTubeV2Service({ videoId: servers[0] });
        break;
      case 'wowza':
        this._loadHlsV3Service('live', { servers, streamKeyParam });
        break;
      default:
        this._runCallbacks('showError', 'showError', 'Unsupported live stream video source.');
    }
  }

  loadOffline(video) {
    this._loadHlsV3Service('url', { videoId: video });
  }

  updateLiveServers(servers, streamKeyParam = 'stream_key') {
    if (this.service) {
      this.service.setVideoSources(wowzaVideoSources(servers, streamKeyParam));
    } else {
      this.loadLive(servers, streamKeyParam);
    }
  }

  togglePlayback() {
    if (this.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  }

  toggleMute() {
    if (this.muted) {
      this.unmute();
    } else {
      this.mute();
    }
  }

  mute() {
    this.muted = true;
  }

  unmute() {
    this.muted = false;
  }

  play() {
    if (this.service) this.service.play();
  }

  pause() {
    if (this.service) this.service.pause();
  }

  seekToLivePosition() {
    if (this.service) this.service.seekToLivePosition();
  }

  loadSubtitles(subtitles) {
    if (this.service && this.service.loadSubtitles) {
      this.service.loadSubtitles(subtitles);
    }
  }

  destroy() {
    if (this.service && this.service.destroy) {
      this.service.destroy();
    }
  }

  // Getters

  get videoServiceName() {
    return this._videoServiceName;
  }

  get isPlaying() {
    return this.state === 'playing';
  }

  get isPaused() {
    return this.state === 'paused';
  }

  get useHlsJs() {
    return !!(this.service && this.service.useHlsJs);
  }

  get videoElement() {
    if (this.service && this.service.videoElement) return this.service.videoElement;
    if (this.service && this.service.video) return this.service.video;

    return undefined;
  }

  get playbackServerIndex() {
    return this.service ? this.service.activeVideoSourceIndex : 0;
  }

  set playbackServer(serverIndex) {
    if (this.service) this.service.activeVideoSourceIndex = serverIndex;
  }

  get ready() {
    return this.service ? this.service.ready : false;
  }

  get loading() {
    return this.service ? this.service.loading : true;
  }

  get duration() {
    return this.service ? this.service.duration : 0;
  }

  get currentTime() {
    return this.service ? this.service.currentTime : 0;
  }

  set currentTime(value) {
    if (this.service) this.service.currentTime = value;
  }

  get programDateTime() {
    return this.service ? this.service.programDateTime : undefined;
  }

  get currentStreamTime() {
    if (this.service && typeof this.service.currentStreamTime !== 'undefined') {
      return this.service.currentStreamTime;
    }

    return null;
  }

  get state() {
    return this.service ? this.service.state : '';
  }

  get seekable() {
    return this.service ? this.service.seekable : true;
  }

  get volume() {
    return this.service ? this.service.volume : 100;
  }

  set volume(volume) {
    if (this.service) this.service.volume = volume;
  }

  get muted() {
    return this.service ? this.service.muted : false;
  }

  set muted(muted) {
    if (this.service) this.service.muted = muted;
  }

  get realPlaybackRate() {
    return this.service ? this.service.realPlaybackRate : 1;
  }

  set realPlaybackRate(rate) {
    if (this.service) this.service.realPlaybackRate = parseFloat(rate);
  }

  get playbackRate() {
    return this.service ? this.service.activePlaybackRate?.playbackRate || 1 : 1;
  }

  set playbackRate(rate) {
    if (this.service) this.service.activePlaybackRateIndex = rate;
  }

  get quality() {
    return this.service ? this.service.activeQualityIndex : 'auto';
  }

  set quality(quality) {
    if (this.service) this.service.activeQualityIndex = quality;
  }

  setQuality(quality) {
    this.quality = quality;
  }

  get subtitlesTrack() {
    return this.service ? this.service.activeSubtitlesIndex : 'Off/off';
  }

  set subtitlesTrack(track) {
    if (this.service) this.service.activeSubtitlesIndex = track;
  }

  setSubtitleTrack(track) {
    this.subtitlesTrack = track;
  }

  get activeVideoSourceUrl() {
    return this.service ? this.service.activeVideoSourceUrl : '';
  }

  get activeQualityName() {
    return this.service ? this.service.activeQualityName : 'Auto';
  }

  get activePlaybackRateValue() {
    return this.service ? this.service.activePlaybackRateValue : 1;
  }

  get live() {
    return this.service ? this.service.live : this._options.live;
  }

  get inLivePosition() {
    return this.service ? this.service.inLivePosition : false;
  }

  set liveFinished(finished) {
    if (this.service) this.service.liveFinished = finished;
  }

  set subtitlesEnabled(enabled) {
    if (this.service) this.service.liveSubtitlesEnabled = enabled;
  }

  set liveSubtitlesDelayMs(delayMs) {
    if (this.service) this.service.liveSubtitlesDelayMs = delayMs;
  }

  get ratio() {
    return this.service ? this.service.ratio : 16 / 9.0;
  }

  set size(size) {
    if (this.service) this.service.size = size;
  }

  // options

  _setVideoElementId() {
    const randomString = Math.random().toString(36).substring(2, 7);
    const id = `slp-${randomString}`;

    this._element.setAttribute('id', id);
  }

  _loadHlsV3Service(service, serviceData) {
    this._videoServiceName = 'hls.js';

    this.service = new HlsV3(this._element, this._createVideoServiceOptions());
    this._addVideoServiceListeners();

    let videoId;
    let videoSources = [];
    if (service === 'yoda') {
      videoId = serviceData.videoId;
      videoSources = yodaVideoSources(serviceData.videoId, serviceData.data);
    } else if (service === 'url') {
      videoSources = urlVideoSources(serviceData.videoId);
    } else if (service === 'live') {
      videoSources = wowzaVideoSources(serviceData.servers, serviceData.streamKeyParam);
    } else {
      this._runCallbacks('showError', 'showError', `Unknown service for HLSv3: ${service}`);
      return;
    }

    this.service.load(videoSources, { videoId });
  }

  _loadVimeoV2Service(videoId) {
    this._videoServiceName = 'vimeo';

    this.service = new VimeoV2(this._element, this._createVideoServiceOptions());
    this._addVideoServiceListeners();
    this.service.load(null, { videoId });
  }

  _loadYouTubeV2Service(videoId) {
    this._videoServiceName = 'youtube';

    this.service = new YoutubeV2(this._element, this._createVideoServiceOptions());
    this._addVideoServiceListeners();
    this.service.load(null, { videoId });
  }

  _loadWistiaV2Service(videoId) {
    this._videoServiceName = 'wistia';

    this.service = new WistiaV2(this._element, this._createVideoServiceOptions());
    this._addVideoServiceListeners();
    this.service.load(null, { videoId });
  }

  _addVideoServiceListeners() {
    this.service.on('ratioChanged', (ratio) => this._runCallbacks('ratioChanged', 'ratioChanged', ratio));
    this.service.on('ready', () => this._runCallbacks('ready', 'ready'));
    this.service.on('loadingChanged', (loading) => {
      this._loadingDurationLogger.logLoadingChange(loading);
      this._runCallbacks('loadingChanged', 'loadingChanged', loading);
    });
    this.service.on('stateChanged', (state) => this._runCallbacks('stateChanged', 'stateChanged', state));
    this.service.on('timesChanged', (currentTime, duration) =>
      this._runCallbacks('timesChanged', 'timesChanged', currentTime, duration),
    );
    this.service.on('seekableChanged', (seekable) =>
      this._runCallbacks('seekableChanged', 'seekableChanged', seekable),
    );
    this.service.on('seekRequest', (before, after) => this._runCallbacks('seekRequest', before, after));
    this.service.on('seeking', () => this._runCallbacks('seeking', 'seeking'));
    this.service.on('seeked', () => this._runCallbacks('seeked', 'seeked'));
    this.service.on('volumeChanged', (volume, muted) =>
      this._runCallbacks('volumeChanged', 'volumeChanged', volume, muted),
    );
    this.service.on('playFailed', () => this._runCallbacks('playFailed', 'playFailed'));
    this.service.on('ended', () => this._runCallbacks('ended', 'ended'));
    this.service.on('programDateTimeChanged', (programDateTime) =>
      this._runCallbacks('programDateTimeChanged', 'programDateTimeChanged', programDateTime),
    );
    this.service.on('timeUpdate', (currentTime, duration) =>
      this._runCallbacks('timeUpdate', 'timeUpdate', currentTime, duration),
    );
    this.service.on('activePlaybackRateIndexChanged', (activePlaybackRateIndex) =>
      this._runCallbacks('playbackRateChanged', 'playbackRateChanged', activePlaybackRateIndex),
    );
    this.service.on('availablePlaybackRatesChanged', (playbackRates) =>
      this._runCallbacks('availablePlaybackRatesChanged', 'availablePlaybackRatesChanged', playbackRates),
    );
    this.service.on('activeQualityIndexChanged', (activeQualityIndex) =>
      this._runCallbacks('qualityChanged', 'qualityChanged', activeQualityIndex),
    );
    this.service.on('availableQualitiesChanged', (qualities) =>
      this._runCallbacks('availableQualitiesChanged', 'availableQualitiesChanged', qualities),
    );
    this.service.on('activeSubtitlesIndexChanged', (activeSubtitlesIndex) =>
      this._runCallbacks('activeSubtitlesChanged', 'activeSubtitlesChanged', activeSubtitlesIndex),
    );
    this.service.on('availableSubtitlesChanged', (subtitles) =>
      this._runCallbacks('subtitlesChanged', 'subtitlesChanged', subtitles),
    );
    this.service.on('renderSubtitlesWord', (subtitles) =>
      this._runCallbacks('renderSubtitlesWord', 'renderSubtitlesWord', subtitles),
    );
    this.service.on('activeVideoSourceIndexChanged', (activeVideoSourceIndex) =>
      this._runCallbacks('playbackServerChanged', 'playbackServerChanged', activeVideoSourceIndex),
    );
    this.service.on('availableVideoSourcesChanged', (videoSources) =>
      this._runCallbacks('availableServersChanged', 'availableServersChanged', videoSources),
    );
    this.service.on('liveStreamStartedChanged', (started) =>
      this._runCallbacks('livestreamStartChanged', 'livestreamStartChanged', started),
    );
    this.service.on('inLivePositionChanged', (inLivePosition) =>
      this._runCallbacks('inLivePositionChanged', 'inLivePositionChanged', inLivePosition),
    );
    this.service.on('streamStartChanged', (streamStart) =>
      this._runCallbacks('streamStartChanged', 'streamStartChanged', streamStart),
    );
    this.service.on('timeSinceStreamStartChanged', (timeSinceStreamStart) =>
      this._runCallbacks('timeSinceStreamStartChanged', 'timeSinceStreamStartChanged', timeSinceStreamStart),
    );
    this.service.on('showError', (error) => this._runCallbacks('showError', 'showError', error));
    this.service.on('reportError', (component, error, data) =>
      this._runCallbacks('reportError', 'reportError', component, error, data),
    );
    this.service.on('debugInfo', (debugInfo) => this._runCallbacks('debugInfo', 'debugInfo', debugInfo));
  }

  _createVideoServiceOptions() {
    return {
      locale: this._options.locale,
      accountId: this._options.accountId,
      presentationId: this._options.presentationId,
      presentationMediaSetId: this._options.presentationMediaSetId,
      analyticsUserUuid: this._options.analyticsUserUuid,
      analyticsSessionUuid: this._options.analyticsSessionUuid,
      embed: this._options.embed,
      contentType: this._options.contentType,
      contentTypeDetails: this._options.contentTypeDetails,
      source: this._options.source,
      autoPlay: this._options.autoPlay,
      defaultStreamQuality: this._options.defaultStreamQuality,
      originalVideoControls: this._options.originalVideoControls,
      initial: this._initial,
      live: this._options.live,
      cmcdEnabled: this._options.cmcdEnabled,
    };
  }
}
