import Hls from 'hls.js';
import urlParam from 'plugins/utilities/url_param';

const HLS_JS_MAX_LOADING_RETRIES = 1000000;
const HLS_JS_MAX_LOADING_RETRY_TIMEOUT = 2000;
const HLS_JS_LOADING_RETRY_DELAY = 100;

function createHlsJs({
  analyticsUserUuid,
  analyticsSessionUuid,
  presentationId,
  contentType,
  videoId,
  cmcdEnabled,
  cmcdSessionId,
  cmcdContentId,
}) {
  return new Hls({
    fragLoadingMaxRetry: HLS_JS_MAX_LOADING_RETRIES,
    fragLoadingRetryDelay: HLS_JS_LOADING_RETRY_DELAY,
    fragLoadingMaxRetryTimeout: HLS_JS_MAX_LOADING_RETRY_TIMEOUT,

    levelLoadingMaxRetry: HLS_JS_MAX_LOADING_RETRIES,
    levelLoadingRetryDelay: HLS_JS_LOADING_RETRY_DELAY,
    levelLoadingMaxRetryTimeout: HLS_JS_MAX_LOADING_RETRY_TIMEOUT,

    manifestLoadingMaxRetry: 0,
    manifestLoadingRetryDelay: HLS_JS_LOADING_RETRY_DELAY,
    manifestLoadingMaxRetryTimeout: HLS_JS_MAX_LOADING_RETRY_TIMEOUT,

    progressive: true,
    startFragPrefetch: true,
    liveSyncDuration: 8,
    debug: !!urlParam('debug_hls_js'),

    maxBufferSize: 60 * 1024 * 1024,
    maxBufferLength: 15,
    maxMaxBufferLength: 25,
    backBufferLength: 120,

    fetchSetup: (context, initParams) => {
      initParams.headers = undefined;
      initParams.mode = 'cors';
      initParams.credentials = 'omit';
      initParams.redirect = 'follow';
      initParams.referrerPolicy = 'origin';

      const url = new URL(context.url);

      if (cmcdEnabled) {
        const slParamData = {
          u: analyticsUserUuid,
          s: analyticsSessionUuid,
          p: presentationId,
          vt: contentType,
        };

        if (videoId) {
          slParamData.v = videoId;
        }

        url.searchParams.set(
          'SL',
          Object.entries(slParamData)
            .map((pair) => pair.join('='))
            .join(','),
        );
      }

      return new Request(url.toString(), initParams);
    },

    cmcd: cmcdEnabled ? { sessionId: cmcdSessionId, contentId: cmcdContentId } : undefined,
  });
}

export default createHlsJs;
