import Hls from 'hls.js';
import log from 'modules/player/log';
import urlParam from 'plugins/utilities/url_param';

import createErrorReportData from './create_error_report_data';

const LOG_TAG = 'HLSv3';

function validatePlaybackSupport({ videoElement }) {
  if (!urlParam('native_hls_player') && Hls.isSupported()) {
    log(LOG_TAG, 'Using HLS.js.');

    return {
      supported: true,
      type: 'hls.js',
    };
  }

  if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
    log(LOG_TAG, 'HLS.js is not supported, using native HLS playback.');

    return {
      supported: true,
      type: 'native',
    };
  }

  console.warn(LOG_TAG, 'not supported');

  return {
    supported: false,
    errors: [
      'Livestream playback is not supported in your browser.',
      'Try upgrading your browser or try using Google Chrome or Firefox.',
      'Report this error to support@slideslive.com if it persists.',
    ],
    errorReport: createErrorReportData(this, null, {
      source: 'load',
      customErrorName: 'NOT_SUPPORTED',
    }),
  };
}

export default validatePlaybackSupport;
