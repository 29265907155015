class VideoServiceCallbacks {
  constructor() {
    this._callbacks = {
      ratioChanged: new Set(),
      ready: new Set(),
      loadingChanged: new Set(),
      stateChanged: new Set(),
      timesChanged: new Set(),
      seekableChanged: new Set(),
      seekRequest: new Set(),
      seeking: new Set(),
      seeked: new Set(),
      volumeChanged: new Set(),
      playFailed: new Set(),
      ended: new Set(),
      programDateTimeChanged: new Set(),
      timeUpdate: new Set(),

      renderSubtitlesWord: new Set(),

      activePlaybackRateIndexChanged: new Set(),
      availablePlaybackRatesChanged: new Set(),
      activeQualityIndexChanged: new Set(),
      availableQualitiesChanged: new Set(),
      activeSubtitlesIndexChanged: new Set(),
      availableSubtitlesChanged: new Set(),
      activeVideoSourceIndexChanged: new Set(),
      availableVideoSourcesChanged: new Set(),

      liveStreamStartedChanged: new Set(),
      inLivePositionChanged: new Set(),
      streamStartChanged: new Set(),
      timeSinceStreamStartChanged: new Set(),

      showError: new Set(),
      reportError: new Set(),
      debugInfo: new Set(),
    };
  }

  on(event, callback) {
    if (!this._callbacks[event]) {
      console.warn(`Register unknown event in VideoServiceCallbacks: ${event}`);
      return;
    }

    this._callbacks[event].add(callback);
  }

  run(event, ...args) {
    if (!this._callbacks[event]) return;

    for (const callback of this._callbacks[event]) {
      callback(...args);
    }
  }
}

export default VideoServiceCallbacks;
