import log from 'modules/player/log';
import fadeIn from 'plugins/animations/fade_in';
import getData from 'plugins/data/get';
import setData from 'plugins/data/set';
import remove from 'plugins/element/remove';
import defer from 'plugins/utilities/defer';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import isWebpSupported from 'plugins/utilities/is_webp_supported';

import LoadingDurationLogger from '../loading_duration_logger';
import Editor from './services/editor';
import Live from './services/live';
import OnDemand from './services/on_demand';

export default class SlidesPlayer {
  constructor(element, options, callbacks) {
    this.element = element;
    this.options = options;
    this.callbacks = callbacks;

    this.props = {
      muted: false,
      loading: null,
      ratio: 16 / 9.0,
      size: null,
    };

    this.currentSlideElement = null;
    this.nextSlideElement = null;

    this.loadingDurationLogger = new LoadingDurationLogger('SLIDES', {
      event: (event) => this.callbacks.loadingEvent(event),
    });
  }

  createSlidesServiceOptions() {
    return {
      presentationId: this.options.presentationId,
      presentationMediaSetId: this.options.presentationMediaSetId,
      analyticsUserUuid: this.options.analyticsUserUuid,
      analyticsSessionUuid: this.options.analyticsSessionUuid,
      embed: this.options.embed,
      accountId: this.options.accountId,
      source: this.options.source,
      cacheTimestamp: this.options.cacheTimestamp,
      playerToken: this.options.playerToken,
      offline: this.options.offline,
      live: this.options.live,
      cmcdEnabled: this.options.cmcdEnabled,

      useWebP: isWebpSupported(),
    };
  }

  createSlidesServiceCallbacks() {
    return {
      ready: () => {
        this.callbacks.ready();
      },
      loadingChanged: (loading, target = null) => {
        if (
          target &&
          ((!this.currentSlideElement && target !== this.nextSlideElement) ||
            (this.currentSlideElement && target !== this.currentSlideElement))
        ) {
          return;
        }

        this.loading = loading;
      },
      currentSlideChanged: (slideIndex, slideCount, slideData, autoPlayVideoSlide = false) => {
        this.loadSlide(slideIndex, autoPlayVideoSlide);
        this.callbacks.currentSlideChanged(slideIndex, slideCount, slideData);
        this.preloadSlides(slideIndex);
      },
      currentPointerChanged: (pointer) => {
        this.callbacks.currentPointerChanged(pointer);
      },
      inSyncChanged: (inSync) => this.callbacks.inSyncChanged(inSync),
      preloadSlides: (index) => this.preloadSlides(index),
      slideLoaded: (target, error) => {
        if (target !== this.nextSlideElement) {
          return;
        }

        this.slideLoaded(target, error);
      },

      updateVolume: (target, volume, muted) => {
        if (target !== this.currentSlideElement) {
          return;
        }

        this.callbacks.videoMutedChanged(this.currentSlideType === 'video', muted);
      },
      videoLoadingChanged: (target, state) => {
        if (target !== this.currentSlideElement) {
          return;
        }

        this.callbacks.videoLoadingChanged(state);
      },
      videoStateChanged: (target, state) => {
        if (target !== this.currentSlideElement) {
          return;
        }

        this.callbacks.videoStateChanged(state);
      },
      videoEnded: (target) => {
        if (this.inSync || target !== this.currentSlideElement) {
          return;
        }

        this.next();
      },
      videoPlaybackRateChanged: (target, playbackRate) => {
        if (target !== this.currentSlideElement) {
          return;
        }

        this.callbacks.videoPlaybackRateChanged(playbackRate);
      },
      videoPlayFailed: (target) => {
        if (target !== this.currentSlideElement) {
          return;
        }

        this.callbacks.videoPlayFailed();
      },
    };
  }

  load(...args) {
    const options = this.createSlidesServiceOptions();
    const callbacks = this.createSlidesServiceCallbacks();

    if (this.options.editor) {
      this.service = new Editor(options, callbacks);
    } else if (this.options.live) {
      this.service = new Live(options, callbacks);
    } else {
      this.service = new OnDemand(options, callbacks);
    }

    if (this.props.size) {
      this.service.size = this.props.size;
    }

    this.service.load(...args);
  }

  loadOffline(slides) {
    const options = this.createSlidesServiceOptions();
    const callbacks = this.createSlidesServiceCallbacks();

    this.service = new OnDemand(options, callbacks);
    this.service.loadOffline(slides);
  }

  dataForSlideIndex(index) {
    return this.service.dataForSlideIndex(index);
  }

  progressImageUrl(time) {
    return this.service.progressImageUrl(time);
  }

  timeForSlideIndex(slideIndex) {
    return this.service.timeForSlideIndex(slideIndex);
  }

  updateSlide(time, programDateTime, force = false, autoPlayVideoSlide = false) {
    this.service.updateSlide(time, programDateTime, force, autoPlayVideoSlide);
  }

  updatePointer(time) {
    if (this.service.updatePointer) this.service.updatePointer(time);
  }

  prev() {
    this.service.prev();
  }

  next() {
    this.service.next();
  }

  setInSync() {
    this.service.setInSync();
  }

  loadSlide(slideIndex, autoPlayVideoSlide) {
    const slide = this.service.slides[slideIndex];

    if (!slide.loadedQuality(this.service.quality)) {
      this.loading = true;
    }

    slide.load(this.service.quality);

    const slideElement = slide.element;
    this.nextSlideElement = slideElement;
    setData(slideElement, 'slideIndex', slideIndex);

    if (slide.type === 'video') {
      slide.muted = this.muted;
      slide.autoPlay = autoPlayVideoSlide;
    }
  }

  slideLoaded(target, error = null) {
    if (error || (target.videoWidth || target.width) + (target.videoHeight || target.height) === 0) {
      this.slideLoadingError(target);
      return;
    }

    this.setNextSlide();
  }

  setNextSlide() {
    const oldSlideElement = this.currentSlideElement;
    const oldSlideIndex = oldSlideElement ? getData(oldSlideElement, 'slideIndex') : null;
    const nextSlideIndex = getData(this.nextSlideElement, 'slideIndex');
    const instantChange = !isVariableDefinedNotNull(oldSlideElement) || oldSlideIndex === nextSlideIndex;
    const slide = this.service.slides[nextSlideIndex];

    this.currentSlideElement = this.nextSlideElement;
    this.nextSlideElement = null;

    this.element.appendChild(this.currentSlideElement);

    this.loading = false;

    const width = slide.width;
    const height = slide.height;
    if (width > 0 && height > 0) {
      const newRatio = width / height;
      if (Math.abs(this.props.ratio - newRatio) > 0.005) {
        this.setRatio(width, height);
      }
    }

    if (instantChange) {
      this.currentSlideElement.style.opacity = 1;

      if (isVariableDefinedNotNull(oldSlideElement) && oldSlideElement !== this.currentSlideElement) {
        defer(() => remove(oldSlideElement));
      }
    } else {
      if (oldSlideElement !== this.currentSlideElement) {
        this.currentSlideElement.style.opacity = 0;
      }

      fadeIn(this.currentSlideElement, {
        easing: 'easeInQuad',
        duration: 100,
        complete: () => {
          setTimeout(() => {
            if (oldSlideElement === this.currentSlideElement) {
              return;
            }

            remove(oldSlideElement);
          }, 500);
        },
      });
    }

    this.fireVideoChanged(nextSlideIndex);
  }

  fireVideoChanged(slideIndex) {
    const slide = this.service.slides[slideIndex];

    this.callbacks.videoMutedChanged(slide.type === 'video', this.muted);

    if (slide.type !== 'video') {
      this.callbacks.videoChanged(null, false);
      return;
    }

    if (!this.inSync) {
      this.callbacks.videoChanged(null, false);
      slide.pause();
      return;
    }

    let shouldBePlaying = false;
    if (slide.autoPlay) {
      slide.autoPlay = false;

      if (this.inSync) {
        shouldBePlaying = true;
        slide.play();
      }
    } else {
      slide.pause();
    }

    this.callbacks.videoChanged(slide, shouldBePlaying);
  }

  slideLoadingError(target) {
    if (target !== this.nextSlideElement) {
      return;
    }

    this.nextSlideElement = null;
    this.loading = false;
  }

  isPreloading(index) {
    return this.service.slides[index].loadingQuality(this.service.quality);
  }

  isPreloaded(index) {
    return this.service.slides[index].loadedQuality(this.service.quality);
  }

  setRatio(w, h) {
    const ratio = w / h;
    if (ratio !== this.props.ratio) {
      this.props.ratio = ratio;
      this.callbacks.ratioChanged(this.props.ratio);
    }
  }

  preloadSlides(index) {
    const from = Math.max(0, index - 4);
    const to = Math.min(this.service.slideCount - 1, index + 4);

    for (let s = from; s <= to; ++s) {
      this.preloadSlide(s);
    }
  }

  preloadSlide(index) {
    if (this.isPreloading(index) || this.isPreloaded(index)) {
      return;
    }

    log('SLIDES', 'preloading', index);

    this.service.slides[index].load(this.service.quality);
  }

  toggleMute() {
    this.muted = !this.muted;
  }

  destroy() {
    if (this.service && this.service.destroy) {
      this.service.destroy();
    }
  }

  get loading() {
    return this.props.loading;
  }

  set loading(value) {
    if (value === this.props.loading) {
      return;
    }

    this.props.loading = value;

    this.callbacks.loadingChanged(this.props.loading);
    this.loadingDurationLogger.logLoadingChange(this.props.loading);
  }

  get ready() {
    return this.service && this.service.ready;
  }

  get currentSlideTime() {
    return this.service ? this.service.currentSlideTime : null;
  }

  get currentSlideIndex() {
    return this.service ? this.service.currentSlideIndex : null;
  }

  get currentSlideType() {
    return this.service ? this.service.currentSlideType : null;
  }

  get currentSlide() {
    return this.service.currentSlide;
  }

  get inSync() {
    return this.service.inSync;
  }

  get ratio() {
    return this.props.ratio;
  }

  set size(size) {
    this.props.size = size;

    if (this.service) {
      this.service.size = size;
    }
  }

  get slideCount() {
    return this.service.slideCount;
  }

  get muted() {
    return this.props.muted;
  }

  set muted(value) {
    this.props.muted = value;

    if (!this.currentSlide || this.currentSlideType !== 'video') {
      return;
    }

    this.currentSlide.muted = value;
  }

  //

  set delay(delay) {
    this.service.delay = delay;
  }

  set streamStart(streamStart) {
    this.service.streamStart = streamStart;
  }

  addLivestreamSlides(slides) {
    this.service.addLivestreamSlides(slides);
  }
}
