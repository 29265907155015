import log from 'modules/player/log';
import lifecycle from 'page-lifecycle/dist/lifecycle.es5';
import addListener from 'plugins/utilities/add_listener';
import now from 'plugins/utilities/now';
import pushDataLayer from 'plugins/utilities/push_data_layer';
import removeListener from 'plugins/utilities/remove_listener';

const Events = {
  NOT_RECORDED: 'not_recorded',
  LIVESTREAM_TERMINATED: 'livestream_terminated',
  LIVESTREAM_STARTED: 'livestream_started',
  LIVESTREAM_NOT_STARTED: 'livestream_not_started',
  LOAD: 'load',
  READY: 'ready',
  INITIAL_LOAD_TIMING: 'initial_load_timing',
  VIDEO_LOADING_START: 'video_loading_start',
  VIDEO_LOADING_END: 'video_loading_end',
  SLIDES_LOADING_START: 'slides_loading_start',
  SLIDES_LOADING_END: 'slides_loading_end',
  ERROR: 'error',
  PLAYING_STATE: 'playing_state',
  PAUSED_STATE: 'paused_state',
  PLAYING: 'playing',
  ENDED: 'ended',
  PLAY_REQUEST: 'play_request',
  PAUSE_REQUEST: 'pause_request',
  TOGGLE_MUTE_REQUEST: 'toggle_mute_request',
  TOGGLE_SLIDE_MUTE_REQUEST: 'toggle_slide_mute_request',
  SEEK_TO_LIVE_POSITION_REQUEST: 'seek_to_live_position_request',
  TOGGLE_FULLSCREEN_REQUEST: 'toggle_fullscreen_request',
  PREV_SLIDE_REQUEST: 'prev_slide_request',
  NEXT_SLIDE_REQUEST: 'next_slide_request',
  SYNC_SLIDES_TO_VIDEO_REQUEST: 'sync_slides_to_video_request',
  SYNC_VIDEO_TO_SLIDES_REQUEST: 'sync_video_to_slides_request',
  SEEK_REQUEST: 'seek_request',
  SEEK_TO_SLIDE_REQUEST: 'seek_to_slide_request',
  SKIP_BACK_REQUEST: 'skip_back_request',
  SKIP_FORWARD_REQUEST: 'skip_forward_request',
  VOLUME_CHANGE_REQUEST: 'volume_change_request',
  SHOW_TOPICS_AND_TRANSCRIPT_REQUEST: 'show_topics_and_transcript_request',
  ADD_BOOKMARK_REQUEST: 'add_bookmark_request',
  UPDATE_BOOKMARK_REQUEST: 'update_bookmark_request',
  REMOVE_BOOKMARK_REQUEST: 'remove_bookmark_request',
  ADD_PLAYLIST_REQUEST: 'add_playlist_request',
  TOGGLE_PLAYLIST_REQUEST: 'toggle_playlist_request',
  VIDEO_QUALITY_CHANGE_REQUEST: 'video_quality_change_request',
  VIDEO_PLAYBACK_SERVER_CHANGE_REQUEST: 'video_playback_server_change_request',
  VIDEO_PLAYBACK_RATE_CHANGE_REQUEST: 'video_playback_rate_change_request',
  SUBTITLE_TRACK_CHANGE_REQUEST: 'subtitle_track_change_request',
  VIDEO_QUALITY_CHANGE: 'video_quality_change',
  VIDEO_PLAYBACK_SERVER_CHANGE: 'video_playback_server_change',
  ZOOM_RATIO_CHANGE: 'zoom_ratio_change',
  PLAY_NEXT_PRESENTATION_REQUEST: 'play_next_presentation_request',
  OPEN_REVIEW_NOTE_REQUEST: 'open_review_note_request',
  RESOLVE_REVIEW_NOTE_REQUEST: 'resolve_review_note_request',
  SEND_REVIEW_NOTE_COMMENT_REQUEST: 'send_review_note_request',
};

export default class PlayerAnalyticsV2 {
  constructor(options, callbacks) {
    this.options = options;
    this.callbacks = callbacks;

    this.props = {
      startedAt: now(),

      sessionEventToken: null,
      sessionEventOrderId: 0,
      sessionEventQueue: [],
      sessionEventQueueDebounceTimeout: null,

      userEmail: null,

      lastTrackPlayingAt: 0,
      shouldTrackStateChangeCounter: 0,

      debug: false,

      queueInterval: null,

      overlaySignupListenerId: null,
      mouseleaveListenerId: null,
      pagehideListenerId: null,
      statechangeListenerId: null,
    };

    this.trackPlaybackPercentage = [5, 10, 25, 50, 75, 90];
    this.trackedPlaybackPercentage = [];

    this.props.overlaySignupListenerId = addListener(window, 'PresentationOverlaySignup:done', (event) => {
      if (this.props.debug) {
        log('LYTICS2', 'email from sign up', event.detail.user);
      }

      this.props.userEmail = event.detail.user;
    });

    this.initializeQueueProcessingTriggers();
  }

  initializeQueueProcessingTriggers() {
    const queueProcessIntervalTimeout = 60000 + Math.floor(Math.random() * 20000);
    this.props.queueInterval = setInterval(this.processSessionEventQueue.bind(this), queueProcessIntervalTimeout);

    if (window !== window.parent) {
      this.props.mouseleaveListenerId = addListener(
        this.options.element,
        'mouseleave',
        this.processSessionEventQueue.bind(this, { useSendBeacon: true }),
      );
    }

    this.props.pagehideListenerId = addListener(
      window,
      'pagehide',
      this.processSessionEventQueue.bind(this, { useSendBeacon: true }),
    );

    this.props.statechangeListenerId = addListener(lifecycle, 'statechange', (event) => {
      if (event.newState === 'hidden' || event.newState === 'terminated') {
        this.processSessionEventQueue({ useSendBeacon: true });
      }
    });
  }

  debouncedProcessSessionEventQueue() {
    if (this.props.sessionEventQueueDebounceTimeout) {
      clearTimeout(this.props.sessionEventQueueDebounceTimeout);
      this.props.sessionEventQueueDebounceTimeout = null;
    }

    setTimeout(
      () => {
        this.props.sessionEventQueueDebounceTimeout = null;
        this.processSessionEventQueue();
      },
      500 + Math.random() * 500,
    );
  }

  createSession() {
    if (this.options.disableTracking) {
      return;
    }

    setTimeout(() => this.sendCreateSession(), 5000 + Math.random() * 5000);
  }

  sendCreateSession() {
    const sessionParams = {
      source_url: this.sourceUrl,
      http_referer: document.referrer || '',
    };

    const params = {
      session_token: this.options.analyticsSessionToken,
      session: sessionParams,
    };

    fetch(this.playerSessionsIngestUrl, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'text/plain; charset=utf-8',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((json) => {
        this.props.sessionEventToken = json.session_event_token;
        this.debouncedProcessSessionEventQueue();
      })
      .catch((error) => {
        console.warn('LYTICS2', 'creating session failed', error);

        setTimeout(() => this.createSession(), 30000 + Math.random() * 30000);
      });
  }

  update(time) {
    const lastTrackPlayingMsAgo = time - this.props.lastTrackPlayingAt;
    if (lastTrackPlayingMsAgo < 0 || lastTrackPlayingMsAgo >= 5000) {
      this.props.lastTrackPlayingAt = time;
      this.trackPlaying();

      if (!this.live) {
        const currentPercentage = this.playerPercentage;
        const nextTrackPercentageIndex = this.trackPlaybackPercentage.findIndex((p) => currentPercentage <= p);
        const currentTrackPercentageIndex =
          nextTrackPercentageIndex === 0 ? -1 : (nextTrackPercentageIndex || this.trackPlaybackPercentage.length) - 1;
        if (currentTrackPercentageIndex >= 0) {
          const currentTrackPercentage = this.trackPlaybackPercentage[currentTrackPercentageIndex];

          if (!this.trackedPlaybackPercentage.includes(currentTrackPercentage)) {
            this.trackedPlaybackPercentage.push(currentTrackPercentage);
            this._trackGoogleAnalyticsEvent('video_progress');
            this._trackGoogleAnalyticsEvent('slp_progress');
          }
        }
      }
    }
  }

  track(event, eventData) {
    if (this.options.disableTracking) {
      return;
    }

    this.props.sessionEventOrderId += 1;

    const sessionEventParams = {
      event,
      order_id: this.props.sessionEventOrderId,
      session_time: this.sessionTime,
      player_time: this.playerTime,
      player_slide: this.playerSlide,
      created_at: new Date().toISOString(),
    };

    if (eventData) {
      sessionEventParams.event_data = eventData;
    }

    this.props.sessionEventQueue.push(sessionEventParams);
  }

  processSessionEventQueue({ useSendBeacon = false } = {}) {
    if (this.props.sessionEventQueueDebounceTimeout) {
      clearTimeout(this.props.sessionEventQueueDebounceTimeout);
      this.props.sessionEventQueueDebounceTimeout = null;
    }

    if (!this.props.sessionEventToken) return;
    if (this.props.sessionEventQueue.length === 0) return;

    const sessionEvents = this.props.sessionEventQueue;
    this.props.sessionEventQueue = [];

    log('LYTICS2', 'send session events', sessionEvents.length);

    const params = {
      session_event_token: this.props.sessionEventToken,
      session_events: sessionEvents,
    };

    const data = JSON.stringify(params);

    if (useSendBeacon) {
      const headers = { type: 'text/plain; charset=utf-8' };
      const blob = new Blob([data], headers);

      navigator.sendBeacon(this.playerSessionEventsIngestUrl, blob);
    } else {
      fetch(this.playerSessionEventsIngestUrl, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
          'Content-Type': 'text/plain; charset=utf-8',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: data,
      }).catch((error) => {
        console.warn('LYTICS2', 'sending session events failed', error);

        this.props.sessionEventQueue = sessionEvents.concat(this.props.sessionEventQueue);
      });
    }
  }

  trackNotRecorded() {
    this.track(Events.NOT_RECORDED);
  }

  trackLivestreamTerminated() {
    this.track(Events.LIVESTREAM_TERMINATED);
  }

  trackLivestreamStarted() {
    this.track(Events.LIVESTREAM_STARTED);
  }

  trackLivestreamNotStarted() {
    this.track(Events.LIVESTREAM_NOT_STARTED);
  }

  trackLoad(playerOptions) {
    this.track(Events.LOAD, { options: playerOptions });
  }

  trackReady() {
    this.track(Events.READY);
  }

  trackInitialLoadTiming(timings) {
    this.track(Events.INITIAL_LOAD_TIMING, timings);
  }

  trackVideoLoadingStart() {
    this.track(Events.VIDEO_LOADING_START);
  }

  trackVideoLoadingEnd(duration) {
    this.track(Events.VIDEO_LOADING_END, { duration });
  }

  trackSlidesLoadingStart() {
    this.track(Events.SLIDES_LOADING_START);
  }

  trackSlidesLoadingEnd(duration) {
    this.track(Events.SLIDES_LOADING_END, { duration });
  }

  trackPlayerError(component, error, warn) {
    this.track(Events.ERROR, { component, error, warn });
  }

  trackPlayingStateChange(firstPlay) {
    if (!this.shouldTrackStateChange()) {
      return;
    }

    let eventData;
    if (firstPlay) {
      eventData = { first_play: firstPlay };
    }

    this.track(Events.PLAYING_STATE, eventData);
    this.debouncedProcessSessionEventQueue();
  }

  trackPausedStateChange() {
    if (!this.shouldTrackStateChange()) {
      return;
    }

    this.track(Events.PAUSED_STATE);
    this.debouncedProcessSessionEventQueue();
  }

  trackPlaying() {
    this.track(Events.PLAYING);
  }

  trackEnded() {
    this.track(Events.ENDED);
    this._trackGoogleAnalyticsEvent('video_complete');
    this._trackGoogleAnalyticsEvent('slp_ended');
  }

  trackPlayRequest(firstPlay) {
    let eventData;
    if (firstPlay) {
      eventData = { first_play: firstPlay };
    }

    this.track(Events.PLAY_REQUEST, eventData);
    this._trackGoogleAnalyticsEvent('slp_play');

    if (firstPlay) {
      this._trackGoogleAnalyticsEvent('video_start');
      this._trackGoogleAnalyticsEvent('slp_first_play');
    }
  }

  trackPauseRequest() {
    this.track(Events.PAUSE_REQUEST);
    this._trackGoogleAnalyticsEvent('slp_pause');
  }

  trackToggleMuteRequest(muted) {
    this.track(Events.TOGGLE_MUTE_REQUEST, { muted });
  }

  trackToggleSlideMuteRequest(muted) {
    this.track(Events.TOGGLE_SLIDE_MUTE_REQUEST, { muted });
  }

  trackSeekToLivePositionRequest(inLivePosition) {
    this.track(Events.SEEK_TO_LIVE_POSITION_REQUEST, { in_live_position: inLivePosition });
  }

  trackToggleFullscreenRequest(active) {
    this.track(Events.TOGGLE_FULLSCREEN_REQUEST, { active });
  }

  trackPrevSlideRequest() {
    this.track(Events.PREV_SLIDE_REQUEST);
    this._trackGoogleAnalyticsEvent('slp_previous_slide');
  }

  trackNextSlideRequest() {
    this.track(Events.NEXT_SLIDE_REQUEST);
    this._trackGoogleAnalyticsEvent('slp_next_slide');
  }

  trackSyncSlidesToVideoRequest() {
    this.track(Events.SYNC_SLIDES_TO_VIDEO_REQUEST);
    this._trackGoogleAnalyticsEvent('slp_sync_slides_to_video');
  }

  trackSyncVideoToSlidesRequest() {
    this.track(Events.SYNC_VIDEO_TO_SLIDES_REQUEST);
    this._trackGoogleAnalyticsEvent('slp_sync_video_to_slides');
  }

  trackSeekRequest(time) {
    this.track(Events.SEEK_REQUEST, { time });
  }

  trackSeekToSlideRequest(slide) {
    this.track(Events.SEEK_TO_SLIDE_REQUEST, { slide });
  }

  trackVolumeChangeRequest(volume) {
    this.track(Events.VOLUME_CHANGE_REQUEST, { volume });
  }

  trackShowTopicsAndTranscriptsRequest() {
    this.track(Events.SHOW_TOPICS_AND_TRANSCRIPT_REQUEST);
  }

  trackAddBookmarkRequest() {
    this.track(Events.ADD_BOOKMARK_REQUEST);

    this._trackGoogleAnalyticsEvent('slp_add_bookmark');
  }

  trackUpdateBookmarkRequest() {
    this.track(Events.UPDATE_BOOKMARK_REQUEST);
    this._trackGoogleAnalyticsEvent('slp_update_bookmark');
  }

  trackRemoveBookmarkRequest() {
    this.track(Events.REMOVE_BOOKMARK_REQUEST);
    this._trackGoogleAnalyticsEvent('slp_remove_bookmark');
  }

  trackAddPlaylistRequest() {
    this.track(Events.ADD_PLAYLIST_REQUEST);
    this._trackGoogleAnalyticsEvent('slp_add_playlist');
  }

  trackTogglePlaylistRequest(playlist, add) {
    this.track(Events.TOGGLE_PLAYLIST_REQUEST, { playlist, add });
    this._trackGoogleAnalyticsEvent('slp_toggle_playlist');
  }

  trackVideoQualityChangeRequest(from, to) {
    this.track(Events.VIDEO_QUALITY_CHANGE_REQUEST, { from, to });
  }

  trackVideoQualityChange(to) {
    this.track(Events.VIDEO_QUALITY_CHANGE, { to });
  }

  trackVideoPlaybackServerChangeRequest(from, to) {
    this.track(Events.VIDEO_PLAYBACK_SERVER_CHANGE_REQUEST, { from, to });
  }

  trackVideoPlaybackServerChange(to) {
    this.track(Events.VIDEO_PLAYBACK_SERVER_CHANGE, { to });
  }

  trackVideoPlaybackRateChangeRequest(from, to) {
    this.track(Events.VIDEO_PLAYBACK_RATE_CHANGE_REQUEST, { from, to });
  }

  trackSubtitleTrackChangeRequest(from, to) {
    this.track(Events.SUBTITLE_TRACK_CHANGE_REQUEST, { from, to });
  }

  trackZoomRatioChange(ratio) {
    this.track(Events.ZOOM_RATIO_CHANGE, { ratio });
  }

  trackPlayNextPresentationRequest(link) {
    this.track(Events.PLAY_NEXT_PRESENTATION_REQUEST, { link });
    this._trackGoogleAnalyticsEvent('slp_play_next_presentation');
  }

  trackOpenReviewNoteRequest() {
    this.track(Events.OPEN_REVIEW_NOTE_REQUEST);
  }

  trackResolveReviewNoteRequest() {
    this.track(Events.RESOLVE_REVIEW_NOTE_REQUEST);
  }

  trackSendReviewNoteCommentRequest() {
    this.track(Events.SEND_REVIEW_NOTE_COMMENT_REQUEST);
  }

  shouldTrackStateChange() {
    if (this.playerTime >= 1000) {
      return true;
    }

    this.props.shouldTrackStateChangeCounter += 1;

    return this.props.shouldTrackStateChangeCounter <= 10;
  }

  destroy() {
    if (this.props.sessionEventQueueDebounceTimeout) {
      clearTimeout(this.props.sessionEventQueueDebounceTimeout);
      this.props.sessionEventQueueDebounceTimeout = null;
    }

    if (this.props.queueInterval) {
      clearInterval(this.props.queueInterval);
      this.props.queueInterval = null;
    }

    if (this.props.overlaySignupListenerId) {
      removeListener(window, { id: this.props.overlaySignupListenerId });
      this.props.overlaySignupListenerId = null;
    }

    if (this.props.mouseleaveListenerId) {
      removeListener(this.options.element, { id: this.props.mouseleaveListenerId });
      this.props.mouseleaveListenerId = null;
    }

    if (this.props.pagehideListenerId) {
      removeListener(window, { id: this.props.pagehideListenerId });
      this.props.pagehideListenerId = null;
    }

    if (this.props.statechangeListenerId) {
      removeListener(lifecycle, { id: this.props.statechangeListenerId });
      this.props.statechangeListenerId = null;
    }
  }

  get playerSessionsIngestUrl() {
    return this.options.analyticsPlayerSessionsIngestUrl;
  }

  get playerSessionEventsIngestUrl() {
    return this.options.analyticsPlayerSessionEventsIngestUrl;
  }

  get sessionTime() {
    return Math.round(now() - this.props.startedAt);
  }

  get live() {
    return this.callbacks.live();
  }

  get presentationId() {
    return this.callbacks.presentationId();
  }

  get sourceUrl() {
    return this.callbacks.sourceUrl();
  }

  get videoUrl() {
    return this.callbacks.videoUrl();
  }

  get videoServiceName() {
    return this.callbacks.videoServiceName();
  }

  get playerTime() {
    return this.callbacks.playerTime();
  }

  get playerDuration() {
    return this.callbacks.playerDuration();
  }

  get playerSlide() {
    return this.callbacks.playerSlide();
  }

  get playerPercentage() {
    return (this.playerTime / this.playerDuration) * 100;
  }

  get playerState() {
    return this.callbacks.playerState();
  }

  _trackGoogleAnalyticsEvent(event) {
    if (this.options.disableTracking || this.options.disableGoogleAnalytics) {
      return;
    }

    pushDataLayer({
      event,
      video_current_time: this.playerTime / 1000.0,
      video_duration: this.playerDuration / 1000.0,
      video_percent: this.playerPercentage,
      video_provider: this.videoServiceName,
      video_title: this.presentationId,
      video_url: this.videoUrl,
      visible: true,
      slp_current_slide: this.playerSlide,
      'gtm.videoCurrentTime': this.playerTime / 1000.0,
      'gtm.videoDuration': this.playerDuration / 1000.0,
      'gtm.videoPercent': this.playerPercentage,
      'gtm.videoProvider': this.videoServiceName,
      'gtm.videoStatus': this.playerState,
      'gtm.videoTitle': this.presentationId,
      'gtm.videoUrl': this.videoUrl,
      'gtm.videoVisible': true,
    });
  }
}
